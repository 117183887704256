export enum ProjectStatus {
  testing = 'テスト中',
  running = '運用中',
  ended = '終了'
}

export type ProjectStatusType = ProjectStatus.testing | ProjectStatus.running | ProjectStatus.ended;

export const ProjectStatusList: ProjectStatusType[] = [
  ProjectStatus.testing,
  ProjectStatus.running,
  ProjectStatus.ended
];

export interface Project {
  id: string,
  apikey: string;
  applicationId: string;
  name: string;
  displayName: string;
  isOpened: boolean;
  domainName: string;
  companyCode: string;
  createdAt: Date | null;
  createdByNagare?: boolean;
  isDeleted?: boolean;
  masterLoginID: string;
  masterLoginPW: string;
  creator: string;
  status: ProjectStatusType;
  URL: string;
}