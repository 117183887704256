

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import Swal from 'sweetalert2'

import * as firebase from 'firebase/app';
import { MatTableDataSource } from "@angular/material/table";
import {
  TABLES,
  PeriodicElement,
  Transaction,
  Columns,
} from "../../assets/table-data";
import { Observable } from 'rxjs';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { TypeTabName, _Alerts, getAlertDate, getMonthShort } from '../alert-summary/alert-summary.component';
import { Userinfo } from 'src/app/services/userinfo';
import { AngularFireAuth } from '@angular/fire/auth';
import { start } from 'repl';
import { AtsusaRepository } from 'src/app/repository/AtsusaRepository';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { addDays } from 'date-fns';
import { SessionService } from 'src/app/service/session.service';
import { DepartmentRepository } from 'src/app/repository/DepartmentRepository';
import { Department } from '../department/department.component';

export interface AlertItem {
  name: string;
  managerName: string;
  deviceNumber: string;
  time: string;
  category: string;
}

type Element = PeriodicElement | Transaction;
@Component({
  selector: 'app-nettyusho-risk',
  templateUrl: './nettyusho-risk.component.html',
  styleUrls: ['./nettyusho-risk.component.css']
})


export class NettyushoRiskComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.nettyushorisk)

  public dataSource: MatTableDataSource<Element> =
    new MatTableDataSource<Element>();

  public columns: Columns[] = [];
  public rowColumns: string[] = [];
  public devices: any[] = [];
  public sortedDevices: any[] = [];
  public users: Observable<any[]>;

  public _TabNames: TypeTabName[] = ["心拍異常", "熱中症", "疲労"];
  public tabName: TypeTabName = "熱中症";

  public days: Date[] = [];

  public subcate: any[] = [];

  onlyActiveDevice = true;

  dateForView = new Date();
  timeForView = new Date();

  numOfItemSlice = 1000000;

  sortHeaderNum = 0;

  public isMaster = false;

  department: Department | null = null;
departmentId = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private userInfo: Userinfo,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private session: SessionService
  ) {
    this.departmentId = this.activatedRoute.snapshot.paramMap.get('department');
    this.session.checkCorrectDepartment(this.departmentId, 'nettyusho-risk').then(d => {
      this.department = d;

      this.afAuth.currentUser.then(async user => {
        const snapShot = await firebase.default.firestore().collection('managers').where('uid', '==', user.uid).get();
        this.isMaster = snapShot.docs.length === 0;
      });
  
      this.days =  [...Array(7)].map((_, i) => {
        let day = new Date();
        day.setDate(day.getDate() - 6 + i);
    
        return new Date(day);
      });
  
      this.userInfo.getStorage('showOnlyActiveDevice').then((ret) => {
        if (ret) {
          this.onlyActiveDevice = true;
        } else {
          this.onlyActiveDevice = false;
        }
      });
      this.initialize();
    });
  }

  public getDevices(): any[] {
    return [...this.devices.sort((a, b) => this.getAlertsSum(a, this.tabName) >= this.getAlertsSum(b, this.tabName) ? -1 : 1)];
  }

  public getShortDate(date: Date): string {
    return this.lang.showDateMD(`${date.getMonth() + 1}/${date.getDate()}`);
  }

  public getWeekDay(date: Date): string {
    return this.lang.showArray(['日', '月', '火', '水', '木', '金', '土'])[date.getDay()];
  }

  public getWearerName(device: any): string {
    const wearerId = device.wearerId;
    if(!wearerId) { return ''; }

    const wearer = this.subcate.find(user => user.id === wearerId);
    return wearer?.name ?? '';
  }

  public getAlertLastDate(device: any, date: Date, tab: TypeTabName): string {
    const alertChunks = this.days.map(day => this.getAlertsRaw(device, day, tab));
    let alerts: string[] = [];
    alertChunks.forEach(chunk => alerts = alerts.concat(chunk));
    const alertDates = alerts.map(alert => getAlertDate(alert)).filter(x => x !== null) as Date[];
    if(alertDates.length === 0) { return ''; }

    const lastDate = alertDates.sort((a, b) => b.getTime() - a.getTime())[0];

    return `${lastDate.getFullYear()}/${lastDate.getMonth() + 1}/${lastDate.getDate()}`;
  }

  public getAlertsRaw(device: any, date: Date, tab: TypeTabName): string[] {
    const alertLabels = _Alerts[this._TabNames.indexOf(tab)]; // _Alerts[this._TabNames.indexOf(this.tabName)];
    return (device.alertsHistory?.filter(alert => alert.indexOf(`${getMonthShort(date)} ${date.getDate() <= 9 ? '0' : ''}${date.getDate()}`) > 0 &&
     alertLabels.some(al => alert.indexOf(al) > -1) && alert.indexOf(`${date.getFullYear()}_`) > 0) ?? []) as string[]; 
  }

  public getAlerts(device: any, date: Date, tab: TypeTabName): number {
    return this.getAlertsRaw(device, date, tab).length;
  }

  public getAlertsSum(device: any, tab: TypeTabName): number {
    return this.days.map(day => this.getAlerts(device, day, tab)).reduce((a, b) => a + b, 0);
  }

  selectTab(tab: TypeTabName) {
    this.tabName = tab;
    this.sortedDevices = this.getDevices();
  }

  clickedCheckBox() {
    this.userInfo.setStorage("showOnlyActiveDevice", this.onlyActiveDevice);
  }

  getUserById(id, users) {
    if (id === undefined || id === "") { return this.lang.show("登録なし") };
    if (users != null) {
      return users.find(item => item.id === id) ? users.find(item => item.id === id) : this.lang.show("登録なし");
    };
  }

  removeAlertHistory() {
    alert("fadfasdf");
  }
  showAlertHistory(dvc) {

    var htmls = "";


    const histories = dvc.alertsHistory.sort((a, b) => {
      const aTime = new Date(a.split("_")[0]);
      const bTime = new Date(b.split("_")[0]);

      return aTime.getTime() > bTime.getTime() ? -1 : 1;
    });

    histories.forEach((hirouAlert) => {
      const info = hirouAlert.split("_");
      const time = info[0];
      const alert = info[1] + info[2];

      const date = Date.parse(time);
      const timeString = this.formatDate(new Date(date), "MM.dd:HH:mm:ss");

      htmls = htmls + '・' + this.lang.show("日時") + ': <b>' + timeString + '</b>, ' +
        '<a >' + this.lang.show(alert) + '</a>' +
        '<br>';
    });







    Swal.fire({
      title: `<strong>${this.lang.show("アラート履歴")}</strong>`,
      icon: 'warning',
      html: htmls,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText:
      this.lang.show('閉じる'),
      // confirmButtonAriaLabel: 'Thumbs up, great!',

    })
  }






  sortTable(n, switchSort) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("myTable2");
    switching = true;
    // Set the sorting direction to ascending:
    dir = "desc";
    /* Make a loop that will continue until
    no switching has been done: */
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the
      first, which contains table headers): */
      for (i = 1; i < (rows.length - 1); i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        /* Check if the two rows should switch place,
        based on the direction, asc or desc: */
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark that a switch has been done: */
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchcount++;
      } else {
        /* If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again. */
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    console.log(event.target.offsetHeight);
    console.log(event.target.scrollTop);
    console.log(event.target.scrollHeight);

    if (event.target.offsetHeight + event.target.scrollTop + 70 >= event.target.scrollHeight) {
      console.log("End");
      this.numOfItemSlice = this.numOfItemSlice + 5;
    }
  }


  readMore() {
    this.numOfItemSlice = this.numOfItemSlice + 5;
  }


  getDateFormated(str) {

    if (str == null) return "";
    return str.split(":")[0];
  }

  getTimeFormated(str) {

    if (str == null) return "";
    return str.split(":")[1] + ":" + str.split(":")[2] + ":" + str.split(":")[3];
  }

  showAlert(str) {

    if (str.phone === undefined) {
      alert(this.lang.show("電話番号が設定されていません"));
    } else {
      alert(this.lang.show電話番号は(str.phone));
    }



  };

  formatDate(date, format) {
    console.log(date)
    format = format.replace(/yyyy/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
    return format;
  };

  async selectUser(device, users, index) {
    console.log(device);
    var selections = {};
    users.forEach((user) => {
      selections[user.id] = user.name;
    });


    const { value: fruit } = await Swal.fire({
      title: this.lang.show('装着中のユーザを選択してください'),
      input: 'select',
      inputOptions: selections,
      inputPlaceholder: this.lang.show('選択する'),
      showCancelButton: true,
      showDenyButton: true,
      denyButtonColor: '#3085d6',
      denyButtonText: this.lang.show(`クリア`),
    })

    if (fruit) {
      firebase.default.firestore().collection('devices').doc(device.id).update({
        wearerId: fruit
      });
      this.devices[index].wearerId = fruit;

    } else if (fruit == undefined) {
      console.log("canceled");
    } else if (fruit == false) {
      firebase.default.firestore().collection('devices').doc(device.id).update({
        wearerId: ""
      });
      this.devices[index].wearerId = "";

    }

  }

  sliceMacAdress(ma) {
    var ret = ma.replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '')

    return ret.slice(-4);
  }

  checkOnGoingStateAndFilterOption(relatedData) {
    if (this.onlyActiveDevice) {
      if (relatedData === undefined) return false;
      if (relatedData[0] === undefined) return false;

      const itemDate = new Date(relatedData[0].timestamp.seconds * 1000).getTime();
      const currentDate = new Date().getTime();

      var difference = currentDate - itemDate;
      if (difference > (100000)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }

  }

  checkOnGoingState(relatedData) {

    if (relatedData === undefined) { return false }
    if (relatedData[0] === undefined) { return false }

    const itemDate = new Date(relatedData[0].timestamp.seconds * 1000).getTime();
    const currentDate = new Date().getTime();

    var difference = currentDate - itemDate;
    // console.log(difference);
    if (difference > (180000)) {
      return false;
    } else {
      return true;
    }

  }

  setInitValue(myDevices: any) {
    this.devices = myDevices;

    this.sortedDevices = this.getDevices();
  }

  async readDevices(): Promise<void> {
    const today = new Date();
    const todayDateInt = today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate();

      await Promise.all(this.devices.map(async doc => {
          let data = doc;

          data['atsusaRisukudo'] = this.days.map(x => -1);
          

          data['atsusaRisukudo'] = await Promise.all(this.days.map(async day => {
            const myDay = addDays(day, -1)
            const dateInt = myDay.getFullYear() * 10000 + (myDay.getMonth() + 1) * 100 + myDay.getDate();
            const rawDateInt = day.getFullYear() * 10000 + (day.getMonth() + 1) * 100 + day.getDate();

            if(rawDateInt === todayDateInt) {
              return await AtsusaRepository.getAtsusaRiskTodayMax(data.id);
            } else {
              const summary = (
                await firebase.default.firestore().collection(data.macAdress + '-summary')
                .where('keisokubi', '==', dateInt)
                .limit(1)
                .get()
                ).docs.map(d => d.data());

              return (summary.length > 0 ? summary[0].atsusaRisukudoDayMax : 0) as number;
            }
          }));

          return data;
      }));

      this.sortedDevices = this.getDevices();
  }

  async initialize(): Promise<void> {
    const subcates = await DepartmentRepository.getDepartmentSubcates(this.department);
    subcates.forEach(subcate => this.subcate.push(subcate));

    this.users = this.db
      .collection<any>('subcate')
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(action => {
          const data = action.payload.doc.data();

          if (data.phone === undefined) {
            data.phone = '';
          }
          return data;
        })
      ));

    const myDevices: any = await DepartmentRepository.getDepartmentDevices(this.department);

    this.setInitValue(myDevices);
    await this.readDevices();
    this.cdr.detectChanges();
  }

  ngOnInit(): void {

    this.activatedRoute.data.subscribe((data) => {
      const tableName = data.tableName as keyof typeof TABLES;
      const table = TABLES[tableName];

      this.dataSource = new MatTableDataSource<Element>(table.data);
      this.columns = table.columns;
      this.rowColumns = this.columns.map((c) => c.def);
    });
  }
}