import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Session } from 'src/app/class/session/session';
import { User } from 'src/app/class/user/user';

import { SessionService } from 'src/app/service/session.service';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ChatMessage } from 'src/app/class/chat-message/chat-message';
import { Patient } from 'src/app/class/patient/patient';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as firebase from 'firebase/app';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { Storage } from '@ionic/storage-angular';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { isJmar, isNagare } from 'src/app/app-routing.module';
import { Department } from 'src/app/pages/department/department.component';
import { _RootTagId, readTag, readTagById, Tag } from 'src/app/repository/tag';
import { Manager } from 'src/app/service/model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.css']
})
export class DefaultHeaderComponent implements OnInit {
  lang = getLangFromComponent(ComponentLabel.defaultheader);
  rootTag: Tag | null = null;

  @Input() departmentId: string = '';
  departmentDropDownShow = false;
  isDeparmentAdmin = false;

  user: User;
  showBackKey = false;
  showSearch = false;
  showUnReadBadgeForPatientList = false;
  showUnReadBadgeForPatientListTalk = false;
  searchText: string;
  siteIsNagare = isNagare;
  siteIsJmar = isJmar;

  manager: Manager | null = null;
  email = '';

  @Output() onValueChanged = new EventEmitter<any>();

  public patients: Observable<Patient[]>;
  path = '';

department: Department | null = null;

  


  constructor(
    private session: SessionService,
    private location: Location,
    private router: Router,
    private db: AngularFirestore,
    private alertViewService: AlertViewService,
    private activatedRoute: ActivatedRoute,
    private localStorage: Storage,

  ) {
    this.path = this.location.path(true);
    this.departmentId = this.activatedRoute.snapshot.paramMap.get('department');
    readTagById(null, this.departmentId).then(tag => this.department = tag);

    // ここにおいてよいか？？
    this.session.checkLogin();
    this.session.isAdminDepaertment().then(isAdmin => this.isDeparmentAdmin = isAdmin);
    this.session.getManager().then(manager => this.manager = manager);
    this.session.getSessionUser().then(user => this.email = user.email);

    this.user = this.session.session.user;
    this.session // 追加
      .sessionState
      .subscribe(data => {
        if (environment.debug) {
          console.log(data);
        }
        this.user = data.user;
        this.putLocalStorageText();


        this.patients = db
          .collection<Patient>('patients', ref => { return ref.where('unReadMembers', 'array-contains', this.user.uid); })
          .snapshotChanges()
          .pipe(
            map(actions => actions.map(action => {
              // 日付をセットしたコメントを返す
              const data = action.payload.doc.data() as Patient;
              const patientData = new Patient(
                data.patient_id,
                data.name,
                data.avatar,
                data.members,
                data.clinic_id,
                data.isboardroom,
                data.disease_name,
                data.birthday,
                data.unReadMembers
              );


              if (data.isboardroom) {
                this.showUnReadBadgeForPatientList = true;
              } else {
                this.showUnReadBadgeForPatientListTalk = true;
              }
              return patientData;
            })));

      });
    if (environment.debug) {
      console.log(this.user);
    }
    this.doesShowBackKey(this.location.path(true));
    this.doesShowSerch(this.location.path(true));

    this.initializeTag();
  }

  async showNotificationSetting() {


    const doc = firebase.default.firestore().collection('alertOptions').doc('default');
    const dataRaw = (await doc?.get())?.data();
    let data = null;

    if(!dataRaw) {
      // 書記データ
      data = [
          {
              "category": "眠気モニタリング",
              "isAlert": true
          },
          {
              "category": "強い眠気モニタリング",
              "isAlert": true
          },
          {
              "category": "転倒モニタリング",
              "isAlert": true
          },
          {
              "isAlert": true,
              "category": "心拍モニタリング"
          },
          {
              "isAlert": true,
              "category": "熱中症危険アラート"
          }
      ];
      doc.set({details: data}, { merge: true });
    } else {
      data = dataRaw.details;
    }

    console.log(dataRaw);


    const { value: formValues } = await Swal.fire({
      title: this.lang.show('メールで通知するアラートを設定してください'),
      html: data.map((c, i) => 
        `<div style="font-size:20px;text-align: left;margin-left: 35%;">` +
        `<label><input style="width: 20px;" type="checkbox" id="checkbox${i}" ${c.isAlert ? 'checked' : ''}>` +
        `${this.lang.show(c.category)}</label></div>`
      ).join(''),
      focusConfirm: false,
      preConfirm: () => {
        const getChecked = (id: string) => document.getElementById(id)['checked'] === true;
        let newDoc = {};
        data.forEach((c, i) => newDoc[c.category] = getChecked(`checkbox${i}`));

        return newDoc;
      }});

      if (formValues) {
        const resultArray = Object.entries(formValues).map(([key, value]) => ({ category: key, isAlert: value }));
        doc.set({details: resultArray}, { merge: true });
      }
  }

  getDepartmentNameToButton() {
    if(this.isDeparmentAdmin && this.department?.id === _RootTagId) {
      return 'TOP';
    } else if(this.departmentId === 'TOP') {
      return 'TOP';
    } else {
      return this.department?.name ?? '';
    }
  }

  async initializeTag() {
    firebase.default.firestore().collection('tags').doc('t0').onSnapshot(async (doc) => {
      this.rootTag = await this.session.getUserRootDepartment();
    });
  }


  toggleDropDown(event) {
    event.stopPropagation();
    event.preventDefault();
    this.departmentDropDownShow = !this.departmentDropDownShow;
  }


  async navigateToDepartment(event, dep: Department) {
    event.stopPropagation();
    event.preventDefault();
    const url = this.getDepartmentRoute(dep);
    this.router.navigate([url]).catch(err => {
      console.log({err});
    });
  }

  getDepartmentName(dep: Department): string {
    return dep.id === '' ? this.lang.show('全ての部署') : dep.name;
  }

  getDepartmentRoute(dep: Department): string {
    const lastDirectory = this.router.url.split('/').pop();
    return (dep.id === '' ? '/TOP/' : `/${dep.id}/`) + lastDirectory;
  }

  getRoute(route): string {
    return ((!this.departmentId || this.departmentId === 'TOP') ? '/TOP' : ('/' + this.departmentId)) + route;
  }

  shouldShowPrimary(str: string): boolean {
    return this.path.indexOf(str) !== -1;
  }

  shouldShowUnReadBadge(patients) {
    if (patients == undefined || patients.length == 0) { return false };
    return this.showUnReadBadgeForPatientListTalk || this.showUnReadBadgeForPatientList;
  }

  ngOnInit(): void {
    if (environment.debug) {
      console.log(this.user);
    }
  }

  doesShowBackKey(path) {
    if (
      path.indexOf("map") != -1 ||
      path.indexOf("item-detail-chart") != -1
    ) {

      this.showBackKey = true;
    }
  }

  doesShowSerch(path) {
    if (
      path.indexOf("item-detail") != -1 ||
      path.indexOf("talk-list") != -1 ||
      path.indexOf("patient-list") != -1
    ) {

      this.showSearch = true;
    }
  }



  logout(): void {  // 追加
    this.session.logout();
  }

  back() {
    this.location.back();
  }

  textUpdated(event) {
    console.log(event)
    if (this.location.path(true).indexOf("item-detail") != -1) {
      this.localStorage.set(this.user.uid + "item-detail", this.searchText);
    } else {
      this.localStorage.set(this.user.uid + "talk-list", this.searchText);
    }

    this.onValueChanged.emit(this.searchText);
  }


  putLocalStorageText() {
    if (this.location.path(true).indexOf("item-detail") != -1) {
      this.localStorage.get(this.user.uid + "item-detail").then((text) => {
        this.searchText = text;
        this.textUpdated(undefined);
      })
    } else {
      this.localStorage.get(this.user.uid + "talk-list").then((text) => {
        this.searchText = text;
        this.textUpdated(undefined);
      })
    }

  }

}
