import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DefaultHeaderComponent } from './components/default-header/default-header.component';
import { PatientDetailComponent } from './pages/patient-detail/patient-detail.component';
import { AccountComponent } from './pages/account/account.component';
import { AddPatientComponent } from './pages/add-patient/add-patient.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { ChatListComponent } from './pages/chat-list/chat-list.component';
import { ChatComponent } from './pages/chat/chat.component';
import { EditTreatmentComponent } from './pages/edit-treatment/edit-treatment.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { ForgotPwComponent } from './pages/forgot-pw/forgot-pw.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConvertDatePipe } from './pipe/convert-date.pipe';
import { AngularFireModule } from '@angular/fire'; // �ǉ�
import { AngularFirestoreModule } from '@angular/fire/firestore'; // �ǉ�
import { AngularFireAuthModule } from '@angular/fire/auth'; // �ǉ�
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';

import { environment } from 'src/environments/environment';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { AccountTwoComponent } from './pages/account-two/account-two.component';
import { AccountThreeComponent } from './pages/account-three/account-three.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxYubinBangoModule } from 'ngx-yubinbango';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import { FilterParentPipe } from './pipe/filter-parent.pipe';
import { FilterChatPipe } from './pipe/filter-chat.pipe';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { OrderByPipe } from './pipe/order-by.pipe';
import { SortByNamePipe } from './pipe/sort-by-name.pipe';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TalkListComponent } from './pages/talk-list/talk-list.component';
import { WithdrawalComponent } from './pages/withdrawal/withdrawal.component';
import { FilterBoardPipe } from './pipe/filter-board.pipe';
import { NgxEchartsModule } from 'ngx-echarts';
import { UserListComponent } from './pages/user-list/user-list.component';
import { NettyushoRiskComponent } from './pages/nettyusho-risk/nettyusho-risk.component';
import { ManagerListComponent } from './pages/manager-list/manager-list.component';
import { DeviceListComponent } from './pages/device-list/device-list.component';
import { MatTableModule } from '@angular/material/table';
import { MapComponent } from './pages/map/map.component';
import { ItemDetailChartComponent } from './pages/item-detail-chart/item-detail-chart.component';
import { ReadQrCodeComponent } from './pages/read-qr-code/read-qr-code.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SummaryDetailComponent } from './pages/summary-detail/summary-detail.component';
import { AlertSummaryComponent } from './pages/alert-summary/alert-summary.component';
import { AlertListComponent } from './pages/alert-list/alert-list.component';
import { NettyushoRiskChartComponent } from './pages/nettyusho-risk-chart/nettyusho-risk-chart.component';
import { HirouListComponent } from './pages/hirou-list/hirou-list.component';
import { StressDetailChartComponent } from './pages/stress-detail-chart/stress-detail-chart.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ConditionListComponent } from './pages/condition-list/condition-list.component';
import { ConditionDetailComponent } from './pages/condition-detail/condition-detail.component';
import { ConditionIconComponent } from './components/condition-icon/condition-icon.component';
import { ProjectListComponent } from './pages/project-list/project-list.component';
import { DeviceDetailComponent } from './components/device-detail/device-detail.component';
import { MyDeviceComponent } from './pages/my-device/my-device.component';
import { MyConditionComponent } from './pages/my-condition/my-condition.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { DepartmentComponent } from './pages/department/department.component';
import { TagComponent } from './pages/tag/tag.component';
import { TagTreeComponent } from './components/tag-treee/tag-treee.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    DefaultHeaderComponent,
    PatientDetailComponent,
    MapComponent,
    AccountComponent,
    AddPatientComponent,
    LoginComponent,
    RegistrationComponent,
    PatientListComponent,
    UserProfileComponent,
    EditProfileComponent,
    ChatListComponent,
    SummaryDetailComponent,
    ItemDetailChartComponent,
    NettyushoRiskChartComponent,
    UserListComponent,
    AlertListComponent,
    NettyushoRiskComponent,
    HirouListComponent,
    ManagerListComponent,
    DeviceListComponent,
    StressDetailChartComponent,
    AlertSummaryComponent,
    ReadQrCodeComponent,
    TalkListComponent,
    SpinnerComponent,
    WithdrawalComponent,
    ChatComponent,
    EditTreatmentComponent,
    ProjectListComponent,
    TermsComponent,
    PolicyComponent,
    ForgotPwComponent,
    ConvertDatePipe,
    PageNotFoundComponent,
    AccountTwoComponent,
    AccountThreeComponent,
    ConditionListComponent,
    ConditionDetailComponent,
    DeviceDetailComponent,
    MyDeviceComponent,
    MyConditionComponent,
    ConditionIconComponent,
    SummaryComponent,
    DepartmentComponent,
    TagComponent,
    TagTreeComponent,
    FilterChatPipe,
    FilterBoardPipe,
    OrderByPipe,
    FilterParentPipe,
    SortByNamePipe
  ],
  entryComponents: [],
  imports: [BrowserModule, BrowserAnimationsModule, IonicModule.forRoot(), AppRoutingModule, MatTableModule,
    IonicStorageModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase), // �ǉ�,
    AngularFirestoreModule,  // �ǉ�
    AngularFireAuthModule,  // �ǉ�
    FormsModule,
    ReactiveFormsModule,
    NgxYubinBangoModule,
    FullCalendarModule,
    ZXingScannerModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),],
  providers: [
    FirebaseX,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SplashScreen,
    StatusBar,
    Badge,
    NgxImageCompressService],
  bootstrap: [AppComponent],
})
export class AppModule { }