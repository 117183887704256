export const dateEqual = (time1: Date, a: { time: any }): boolean => {
  const time2 = a.time.toDate();
  if(!time1 || !time2) return false;
  return time1.getFullYear() === time2.getFullYear() && time1.getMonth() === time2.getMonth() && time1.getDate() === time2.getDate();
}

const monthENs = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const monthESs = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
const addZero = (num: number): string => num < 10 ? '0' + num : `${num}`;

export const toShortDateJA = (date: Date): string => {
  console.log({date});
  const now = new Date();
  if(date.getFullYear() !== now.getFullYear()) {
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  } else if(date.getMonth() !== now.getMonth() || date.getDate() !== now.getDate()) {
    return `${date.getMonth() + 1}/${date.getDate()}`;
  } else {
    return `${addZero(date.getHours())}:${addZero(date.getMinutes())}`;
  }
};

export const toShortDateEN = (date: Date): string => {
  const now = new Date();
  if(date.getFullYear() !== now.getFullYear()) {
    return `${monthENs[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  } else if(date.getMonth() !== now.getMonth() || date.getDate() !== now.getDate()) {
    return `${monthENs[date.getMonth()]} ${date.getDate()}`;
  } else {
    return `${addZero(date.getHours())}:${addZero(date.getMinutes())}`;
  }
};

export const toShortDateES = (date: Date): string => {
  const now = new Date();
  if(date.getFullYear() !== now.getFullYear()) {
    return `${date.getDate()} de ${monthESs[date.getMonth()]} ${date.getFullYear()}`;
  } else if(date.getMonth() !== now.getMonth() || date.getDate() !== now.getDate()) {
    return `${monthESs[date.getMonth()]} de ${date.getDate()}`;
  } else {
    return `${addZero(date.getHours())}:${addZero(date.getMinutes())}`;
  }
};