import { Injectable } from '@angular/core';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import * as firebase from 'firebase';
const standardDeviation = require('ml-array-standard-deviation').default;
const mean = require('ml-array-mean').default;
import { Storage } from '@ionic/storage-angular';
// import { File } from '@awesome-cordova-plugins/file/ngx';
import { AlertViewService } from './alert-view.service';
import { ActivatedRoute } from '@angular/router';
import { getTitles } from '../pages/chat-list/chat-list';
import { getToday } from '../repository/Tiime';

declare let cordova: any;
declare let formulajs: any;
declare let google: any;

/**
 * A simple infos/config class for storing key/value pairs with persistence.
 */
@Injectable({
  providedIn: 'root'
})
export class Userinfo {

  public titles = getTitles();


  public alertIds: any;
  public alertList = [];
  public subscriber;
  public clicksubscriber;
  public doesRecordGeoLocation = true;
  public doesReceiveNotification = true;
  public users = [];
  public events = [];
  public user: any;
  public macAddress: any;
  public itemOrder: any;
  public enablePush: boolean;
  public latitude = 35.658584;
  public longitude = 139.7454316;
  public locationName = "";
  public altitude = 0;
  public accuracy = 0;
  public altitudeAccuracy = 0;
  public heading = 0;
  public speed = 0;
  public gravityX = 0;
  public gravityY = 0;
  public gravityZ = 0;
  public timestamp = 0;

  // 運転アラート
  public driveAlert = "";
  // 暑さ指数
  public atsusaAlert = "";
  // 心拍判定
  public shinpakuhaitei = "";
  // 熱中症アラート
  public nettyusyoAlert = "";
  // 疲労アラート
  public hirouAlert = "";
  // 眠気アラート
  public nemukeAlert = "";



  public newX = 0;
  public newY = 0;
  public newZ = 0;
  public directionAcc = 0;
  public directionSideAcc = 0;
  public XYdegree = 0;
  public XYZdegree = 0;
  public newYOffset = 0;
  public newZOffset = 0;
  public OnGoingDegree = 0;

  public headingHistory = [0, 0, 0, 0, 0]
  public realtimeOrientation = {
    alpha: 0, beta: 0, gamma: 0
  }

  public gX = 0;
  public gY = 0;
  public gZ = 0;
  public mX = 0;
  public mY = 0;
  public mZ = 0;

  public accelerometerWorldDataX = 0;
  public accelerometerWorldDataY = 0;
  public accelerometerWorldDataZ = 0;

  public vehicleStatus = 0;

  public enableSendingToCloud = true;


  public AC2 = 0;
  public Diff2LSB = 0;
  public AccX = 0;
  public AccY = 0;
  public AccZ = 0;

  public datasArrayPR = []
  public datasArrayPR10min = []
  public datasAnsCar = []
  public datasNR = []
  public datasTP = []
  public datasGravities = []
  public datasArrayRRI = []
  public datasArrayLFHF = []

  public deviceType = "";
  public time270ms = "";
  public previousSkinTemp = 0;
  public SkinTemp = 0;
  public previousPR = 0;
  public PR = 0;
  public BloodAge = 0;
  public BloodAgeRel = 0;
  public NR = 0;
  public HS = 0;
  public RRI = 0;
  public RRIRel = 0;
  public BatteryStat = 0;
  public AGStat = 0;
  public LF = 0;
  public HF = 0;
  public LFHF = 0.5;
  public pNN50 = 0.5;
  public TP = 0;
  public Nemukedo = 0;
  public ansCar = 0;
  public ansHR = 0;
  public wbgt = 0;
  public mPR = 0;
  public pressure = 0;
  public CVRR = 0;
  public previousPressure = 0;
  public hirodo = 0;
  public driveScore = 0;
  public countOfBrakeStopStart = 0;
  public atusaRisukudo = 0;


  // 表示向けの数値
  public kakuseido = 0;
  public kaitekido = 0;

  public csvs = [];
  public firebaseConfig: any;

  public thirtySecDatasDate = [];
  public thirtySecDatasPR = [];
  public thirtySecDatasSkinTemp = [];

  public durationOfPR = 0;
  public durationOfSkinTemp = 0;

  private medianListOfPR = [];
  public weatherInfo: any;
  public currentWeather;
  public currentTemp;
  public todayMaxTemp;
  public todayMinTemp;
  public currentHumidity;
  public weatherInfoDay: any;
  public atsusaRisukudo = 0;
  public appversion = 0;
  public previousAtsusaRisukudo = 0;
  public PRScore = 0;
  public LFHFScore = 0;
  public hirouScore = 0;
  public CVRRScore = 0;
  public LPScore = 0;
  public tenkiPressure = 0;
  public preTenkiPressure = 0;
  public nemukeScore = 0;

  private _storage: Storage | null = null;
  spendSeconds = 0;

  public threasholds = {
    vehiclestop: 0.5,
    lowPassAlpha: 0.8,
  };






  constructor(
    private storage: Storage,
    public loadingController: LoadingController,
    // private file: File,
    public platform: Platform,
    public alertService: AlertViewService,
    public alertControler: AlertController,
    private route: ActivatedRoute,

  ) {
    console.log("initialize firebase");

    this.macAddress = (this.route.snapshot.paramMap.get('id'));


    setTimeout(() => {

      this.getAddress();
      this.getWeatherInfo();
    }, 7000);

    setInterval(() => {
      console.log("start setInterval");
      this.getAddress();
      this.getWeatherInfo();

    }, 5000);

    setInterval(() => {
      this.spendSeconds++;

    }, 1000);

    // 画面表示項目のための計算処理
    setInterval(() => {
      this.kakuseido = this.getNormalizedValue(this.LFHF, 0, 1.5, 0, 100);
      if (this.deviceType === "G41") {
        // this.pNN50 = this.getPNN50() / 100;
        // this.kaitekido = this.getNormalizedValue(this.pNN50, 0, 1.0, 0, 100);
      } else {
        var p = this.pNN50;
        if (p > 1.0) {
          p = 1.0;
        }
        this.kaitekido = this.getNormalizedValue(p, 0, 1.0, 0, 100);
      }

    }, 1000);

  }



  getNormalizedValue(value, min, max, valuemin, valuemax) {
    return ((value / max) * valuemax) | 0;
  }







  dateFormat = {
    _fmt: {
      "yyyy": function (date) { return date.getFullYear() + ''; },
      "MM": function (date) { return ('0' + (date.getMonth() + 1)).slice(-2); },
      "dd": function (date) { return ('0' + date.getDate()).slice(-2); },
      "hh": function (date) { return ('0' + date.getHours()).slice(-2); },
      "mm": function (date) { return ('0' + date.getMinutes()).slice(-2); },
      "ss": function (date) { return ('0' + date.getSeconds()).slice(-2); }
    },
    _priority: ["yyyy", "MM", "dd", "hh", "mm", "ss"],
    format: function (date, format) {
      return this._priority.reduce((res, fmt) => res.replace(fmt, this._fmt[fmt](date)), format)
    }
  };



  setItemOrder(item) {
    return new Promise<any>((resolve, reject) => {
      // console.log(item);
      this.setStorage("itemorder", item);
    });
  }



  getWeatherInfo() {

    if (!this.enableSendingToCloud) return;

    return new Promise<any>((resolve, reject) => {
      console.log({ latitude: this.latitude, longitude: this.longitude})
      var url = "https://api.open-meteo.com/v1/forecast?latitude=" + this.latitude + "&longitude=" + this.longitude + "&hourly=temperature_2m,relativehumidity_2m,precipitation,weathercode&daily=temperature_2m_max,temperature_2m_min,precipitation_sum&timezone=Asia%2FTokyo";

      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);

      var self = this;

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          console.log(xhr.status);
          // console.log(xhr.responseText);
          self.weatherInfo = JSON.parse(xhr.responseText)["hourly"];
          self.weatherInfoDay = JSON.parse(xhr.responseText)["daily"];

          const d = new Date();
          let hour = d.getHours();

          self.currentWeather = self.weatherInfo.weathercode[hour];
          self.currentTemp = self.weatherInfo.temperature_2m[hour];
          self.todayMaxTemp = Math.max(...self.weatherInfo.temperature_2m.slice(0, 24));
          self.todayMinTemp = Math.min(...self.weatherInfo.temperature_2m.slice(0, 24));
          self.currentHumidity = self.weatherInfo.relativehumidity_2m[hour];

          // console.log(self.weatherInfo);
          resolve(self.weatherInfo);
        } else {

        }
      };

      xhr.send();
    });
  }

  getAddress() {
    return new Promise<any>((resolve, reject) => {
      //入力した緯度・経度を取得します。

      var url = "http://geoapi.heartrails.com/api/json?method=searchByGeoLocation&y=" + this.latitude + "&x=" + this.longitude;

      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      var self = this;
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {

          var ret = JSON.parse(xhr.responseText);
          self.locationName = ret.response.location[0].prefecture + ret.response.location[0].city;
        } else {
          //console.error("位置情報取得エラー");
        }
      };

      xhr.send();
    });
  }

  getThresholds() {
    const ref = firebase.default.firestore().collection("thresholds").doc("values");
    ref.get().then(doc => {
      const value: any = doc.data();
      if (value) {
        this.threasholds = value;
      } else {
        firebase.default.firestore().collection("thresholds").doc("values").set(this.threasholds);
      }
    }).catch(err => {
      console.log('Error getting documents', err);
    });
  }

  setWeatherUserData() {
    const time = firebase.default.firestore.FieldValue.serverTimestamp();
    if (this.macAddress) {
      firebase.default.firestore().collection("weather").doc().collection(this.macAddress).add({
        timestamp: time,
        location: new firebase.default.firestore.GeoPoint(this.latitude, this.longitude),
        weatherInfo: this.weatherInfo
      }).then((res) => {
        // console.log(res);
      }).catch((err) => {
        console.log(err);
      });

    }
  }

  setSeveralUserData() {



    if (this.macAddress) {

      this.thirtySecDatasDate.push(this.spendSeconds);
      this.thirtySecDatasPR.push(this.PR);
      this.thirtySecDatasSkinTemp.push(this.SkinTemp ? this.SkinTemp : 35.0);

      while (this.thirtySecDatasDate.length > 88) {
        this.thirtySecDatasDate.shift();
        this.thirtySecDatasPR.shift();
        this.thirtySecDatasSkinTemp.shift();
      }

      console.log(this.thirtySecDatasDate.length);


      // PR の中央値を算出
      this.mPR = this.median(this.thirtySecDatasPR.concat());
      this.medianListOfPR.push(this.mPR);
      while (this.medianListOfPR.length > 88) {
        this.medianListOfPR.shift();
      }


      // Create Slope Duration of a regression line.
      const dPR = formulajs.SLOPE(this.medianListOfPR, this.thirtySecDatasDate);
      const dSkin = formulajs.SLOPE(this.thirtySecDatasSkinTemp, this.thirtySecDatasDate);

      if (!isNaN(dPR) && !isNaN(dSkin) && (this.spendSeconds > 360)) {
        this.durationOfPR = this.durationOfPR + dPR;
        this.durationOfSkinTemp = this.durationOfSkinTemp + dSkin;
        this.Nemukedo = this.durationOfSkinTemp - this.durationOfPR;
      } else {

        if (isNaN(dPR)) {
          console.log("dPR was NaN occoured!!");
        } else if (isNaN(dSkin)) {
          console.log("dSkin was NaN occoured!!");
        } else {
          console.log("Fatal error was occoured!!");
        }

        console.log(this.thirtySecDatasDate);
        console.log(this.medianListOfPR);
        console.log(this.thirtySecDatasSkinTemp);
      }
    }
  }

  slope(X, Y) {

    var Slope, Intercept, SX = 0, SY = 0,
      SXX = 0, SXY = 0, SYY = 0,
      SumProduct = 0, N = X.length;

    for (var i = 1; i < N; i++) {
      SX = SX + Math.log(X[i]);
      SY = SY + Y[i];
      SXY = SXY + Math.log(X[i]) * Y[i];
      SXX = SXX + Math.log(X[i]) * Math.log(X[i]);
      SYY = SYY + Y[i] * Y[i];
    }

    const retA = ((N * SXY) - (SX * SY))
    const retB = ((N * SXX) - (SX * SX));

    return retA / retB;

    // Intercept = (SY - (Slope * SX)) / N;
  }

  convertDateToDiffTime(array) {
    var ret = [];
    array.forEach((date) => {
      ret.push(Math.floor((date - array[0]) / 1000));
    });

    return ret;
  }


  median(values) {
    if (values.length === 0) throw new Error("No inputs");

    values.sort(function (a, b) {
      return a - b;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2)
      return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  }




  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  async getStorage(name) {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    if (this._storage) {
      return await this._storage.get(name);
    } else {
      const storage = await this.storage.create();
      this._storage = storage;
      return await this._storage.get(name);
    }
  }

  async setStorage(name, value) {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    return await this._storage.set(name, value);
  }







  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      showBackdrop: false,
      duration: 10000,
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }




  setHirodo() {
    let avg = this.average(this.datasTP);
    let sd = this.standardDeviation(this.datasTP, avg);
    let ssArr = this.standardScore(this.datasTP, avg, sd);

    this.hirodo = 100 - ssArr[this.datasTP.length - 1];
  }

  setDriveScore() {
    let avg = this.average(this.datasGravities);
    let sd = this.standardDeviation(this.datasGravities, avg);
    let ssArr = this.standardScore(this.datasGravities, avg, sd);

    this.driveScore = 100 - ssArr[this.datasGravities.length - 1];

    if (this.driveScore < 30) {
      this.countOfBrakeStopStart++;
    }

    if (this.driveScore <= 38) {

    } else if (this.driveScore > 38 && this.driveScore <= 45) {

    } else {

    }
  }

  public startWatchingWearOSDatas() {

    setInterval(() => {
      this.getWearOSDatas();
    }, 1000);
  }

  isFirstWeatherInfo = true;

  getWearOSDatas() {

    var self = this;
    this.previousAtsusaRisukudo = this.atsusaRisukudo;

    const ref = firebase.default.firestore().collection(this.macAddress).where('timestamp', '>=', getToday()).orderBy('timestamp', 'desc').limit(1);
    ref.get().then(snapshot => {
      snapshot.forEach(doc => {
        const data = doc.data();

        // console.log("Received New Wear OS Datas");
        // console.log(data);


        this.timestamp = data.timestamp ? data.timestamp : 0;
        this.latitude = data.location ? data.location.latitude : 0;
        this.longitude = data.location ? data.location.longitude : 0;
        this.gravityX = data.gravityX ? data.gravityX : 0;
        this.gravityY = data.gravityY ? data.gravityY : 0;
        this.gravityZ = data.gravityZ ? data.gravityZ : 0;
        this.altitude = data.altitude ? data.altitude : 0;
        this.accuracy = data.accuracy ? data.accuracy : 0;
        this.altitudeAccuracy = data.altitudeAccuracy ? data.altitudeAccuracy : 0;
        this.heading = data.heading ? data.heading : 0;
        this.speed = data.speed ? data.speed : 0;
        this.AC2 = data.AC2 ? data.AC2 : 0;
        this.Diff2LSB = data.Diff2LSB ? data.Diff2LSB : 0;
        this.AccX = data.AccX ? data.AccX : 0;
        this.AccY = data.AccY ? data.AccY : 0;
        this.AccZ = data.AccZ ? data.AccZ : 0;
        this.SkinTemp = data.SkinTemp ? data.SkinTemp : 0;
        this.PR = data.PR ? data.PR : 0;
        this.BloodAge = data.BloodAge ? data.BloodAge : 0;
        this.BloodAgeRel = data.BloodAgeRel ? data.BloodAgeRel : 0;
        this.NR = data.NR ? data.NR : 0;
        this.HS = data.HS ? data.HS : 0;
        this.RRI = data.RRI ? data.RRI : 0;
        this.RRIRel = data.RRIRel ? data.RRIRel : 0;
        this.BatteryStat = data.BatteryStat ? data.BatteryStat : 0;
        this.AGStat = data.AGStat ? data.AGStat : 0;
        this.LF = data.LF ? data.LF : 0;
        this.HF = data.HF ? data.HF : 0;
        this.LFHF = data.LFHF ? data.LFHF : 0;
        this.TP = data.TP ? data.TP : 0;
        this.ansCar = data.ansCar ? data.ansCar : 0;
        this.ansHR = data.ansHR ? data.ansHR : 0;
        this.Nemukedo = data.nemukedo ? data.nemukedo : 0;
        this.wbgt = data.atsusa ? data.atsusa : 0;
        this.durationOfPR = data.sumOfSlopeMPR ? data.sumOfSlopeMPR : 0;
        this.durationOfSkinTemp = data.sumOfSlopeSkinTMP ? data.sumOfSlopeSkinTMP : 0;
        this.mPR = data.mPR ? data.mPR : 0;
        this.driveAlert = data.driveAlert ? data.driveAlert : 0;
        this.hirodo = Math.floor(data.hiroudo ? data.hiroudo : 0);
        this.shinpakuhaitei = data.shinpakuhaitei ? data.shinpakuhaitei : 0;
        this.hirouAlert = data.hirouAlert ? data.hirouAlert : 0;
        this.atsusaAlert = data.atsusaAlert ? data.atsusaAlert : 0;
        this.nettyusyoAlert = data.nettyusyoAlert ? data.nettyusyoAlert : 0;
        this.nemukeAlert = data.nemukeAlert ? data.nemukeAlert : 0;
        this.pNN50 = data.pNN50 ? data.pNN50 : 0;
        this.pressure = data.pressure ? data.pressure : 0;
        this.CVRR = data.CVRR ? data.CVRR : 0;
        this.atsusaRisukudo = data.atsusaRisukudo ? data.atsusaRisukudo : 0;
        this.appversion = data.appversion ? data.appversion : 0;
        this.PRScore = Math.floor(data.PRScore ? data.PRScore : 0);
        this.LFHFScore = Math.floor(data.LFHFScore ? data.LFHFScore : 0);
        this.hirouScore = Math.floor(data.hurouScore ? data.hurouScore : 0);
        this.CVRRScore = Math.floor(data.CVRRScore ? (100 - data.CVRRScore) : 0);
        this.LPScore = Math.floor(data.LPScore ? data.LPScore : 0);
        this.preTenkiPressure = this.tenkiPressure;
        this.tenkiPressure = Math.floor(data.tenkiPressure ? data.tenkiPressure : 0);
        this.nemukeScore = Math.floor(data.nemukeScore ? data.nemukeScore : 0);

        if(this.isFirstWeatherInfo) {
          this.getAddress();
          this.getWeatherInfo();
          this.isFirstWeatherInfo = false;
        }
      });
    }).catch(err => {
      // console.log('Error getting documents', err);
    });
  }

  getStandardDeviation(name) {


    var rriarray = this.datasArrayRRI.slice(0, this.datasArrayRRI.length);
    if (rriarray.length > 11) {

      if (name === "PR") {
        return parseInt(standardDeviation(this.datasArrayPR), 10);
      } else if (name === "RRI") {
        const ret = parseInt(standardDeviation(rriarray.splice(10, rriarray.length)), 10);
        return ret;
      } else if (name === "LFHF") {
        return parseInt(standardDeviation(this.datasArrayLFHF), 10);
      }
    }

    return 0;
  }

  getAverage(name) {


    if (name === "PR" && this.datasArrayPR.length) {
      return parseInt(mean(this.datasArrayPR), 10);
    } else if (name === "RRI") {
      return parseInt(mean(this.datasArrayRRI), 10);
    } else if (name === "LFHF") {
      if (this.datasArrayLFHF.length > 0) {
        return mean(this.datasArrayLFHF);
      } else {
        return 0;
      }
    }
    return 0;
  }

  getMax(name) {
    if (name === "PR" && this.datasArrayPR.length) {
      return Math.max(...this.datasArrayPR)
    } else if (name === "RRI") {
      return Math.max(...this.datasArrayRRI)
    } else if (name === "LFHF") {
      if (Math.max(...this.datasArrayLFHF) == -Infinity) {
        return 0;
      } else {
        return Math.max(...this.datasArrayLFHF);
      }
    }

    return 0;
  }

  async appStopAlert() {
    const alert = await this.alertControler.create({
      cssClass: 'my-custom-class',
      header: '終了',
      message: 'G41の接続が停止しました。アプリを終了します。',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Ok');

            this.presentLoading();
            let self = this;

            setTimeout(() => {
              // self.uploadFile(self.csvs[0].path, self.csvs[0].file).then((ret) => {
              //   self.uploadFile(self.csvs[1].path, self.csvs[1].file).then((ret) => {
              navigator['app'].exitApp();
              self.loadingController.dismiss();
              //   })
              // })
            }, 2000);
          }
        }
      ]
    });

    await alert.present();
  }


  // uploadFile(path, file) {
  //   return new Promise<any>((resolve, reject) => {

  //     file = file.replace('Tmp', '000');
  //     this.file.readAsArrayBuffer(path, file).then((buffer) => {
  //       const fileBlob = new Blob([buffer], { type: 'text/csv' });
  //       let storageRef = firebase.storage().ref().child('csvs/' + this.macAddress + "/" + this.dateFormat.format(new Date(), 'yyyy-MM-dd-hh-mm-ss') + '/' + file);

  //       storageRef.put(fileBlob).then(function (snapshot) {
  //         resolve(true);
  //       }).catch((err) => {
  //         console.log(err);
  //       });
  //     });
  //   });
  // }



  average(x) {
    let n = x.length;
    let avg = 0;

    for (let i = 0; i < n; i++) {
      avg += x[i];
    }
    return avg / n; //  (1 / n * avg)
  }

  standardDeviation(x, avg) {
    let n = x.length;
    let sum = 0;
    for (let i = 0; i < n; i++) {
      sum += Math.pow(x[i] - avg, 2);
    }
    return Math.sqrt(sum / n);
  }

  standardScore(x, avg, sd) {
    let ssArr = [];
    let n = x.length;
    for (let i = 0; i < n; i++) {
      let ti = (10 * (x[i] - avg) / sd) + 50;
      ssArr.push(ti);
    }

    return ssArr;
  }

}
