

import { Component, Input, OnInit } from '@angular/core';
import {
  PeriodicElement,
  Transaction,
} from "../../assets/table-data";
import { addTag, deleteTag, moveDownTag, moveUpTag, Tag, updateTagName } from 'src/app/repository/tag';
import Swal from 'sweetalert2';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';

type Element = PeriodicElement | Transaction;
let openTagId = '';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit {
  lang = getLangFromComponent(ComponentLabel.managerlist);
  @Input() parent: Tag | null = null;
  @Input() tag: Tag;
  @Input() depth = 0;
  isOpen = true;

  constructor(
  ) {
    
  }

  async getEditName(name: string) {
    const { value: formValues } = await Swal.fire({
      title: this.lang.show('部署の編集'),
      html:
        '<div style="display: grid; grid-template-columns: 5rem 1fr;">' +
        `<div style="padding-top: 2rem;">${this.lang.show("名前")}</div><div><input id="swal-input1" class="swal2-input" value="${name}"></div>` +
        '</div>',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: async () => {
        const value = document.getElementById('swal-input1')["value"]

        if (value.length === 0) {
          alert(this.lang.show("名前の項目が未入力です"));
          return false;
        }
        return value;
      },
    });

    if(!formValues) return;

    if (formValues) {
        return formValues as string;
    } else {
      return '';
    }
  }

  async addTag(event: MouseEvent) {
    this.onCloseMenu(event);
    const name = await this.getEditName('');
    if(!name) return;

    await addTag(this.tag, name);
  }

  async renameTag(event: MouseEvent) {
    this.onCloseMenu(event);
    const name = await this.getEditName(this.tag.name);
    if(!name) return;

    await updateTagName(this.tag, name);
  }

  async moveDown(event: MouseEvent) {
    this.onCloseMenu(event);
    await moveDownTag(this.parent, this.tag);
  }

  async moveUp(event: MouseEvent) {
    this.onCloseMenu(event);
    await moveUpTag(this.parent, this.tag);
  }

  async delete(event: MouseEvent) {
    this.onCloseMenu(event);
    await deleteTag(this.parent, this.tag);
  }

  isEdge(): boolean {
    return (this.tag?.children?.length ?? 1) === 0;
  }

  isOpenMenu() {
    return openTagId === this.tag.id;
  }

  onToggleOpen(event: MouseEvent) {
    this.isOpen = !this.isOpen && !this.isEdge();
    event.stopPropagation();
  }

  onToggleMenu(event: MouseEvent) {
    if(this.isOpenMenu()) {
      openTagId = '';
    } else {
      openTagId = this.tag.id;
    }

    event.stopPropagation();
  }

  onCloseMenu(event: MouseEvent) {
    openTagId = '';
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.isOpen = !this.isEdge();
  }
}
