export default class Num {
  static toIntOrHyphenReverse(value: number | undefined): string {
    return value === 0 || this.checkErrorValue(value) ? '-' : this.addComma(Math.floor(100 - value));
  }

  static toIntOrHyphen(value: number | undefined): string {
    return value === 0 || this.checkErrorValue(value) ? '-' : this.addComma(Math.floor(value));
  }

  static addComma(value: number | undefined): string {
    if(value === undefined) return '-';
    return value.toString().replace(/(\d)(?=(\d{3})+$)/g , '$1,');
  }

  static checkErrorValue(n: any): boolean {
    return n === undefined || isNaN(n) || n === null || n === Infinity || n === -Infinity
  }
}