import * as firebase from 'firebase/app';
import { Distinct } from './Distinct';
import { Department } from '../pages/department/department.component';
import { _RootTagId, getSameAndUserTags } from './tag';

export class DepartmentRepository {
  static async getDepartmentSubcates(department: Department | null): Promise<any[]> {
    if(department.id === _RootTagId) {
      return (await firebase.default.firestore().collection('subcate')
        .get())
        .docs
        .map(doc => doc.data());  
    }

    const departments = getSameAndUserTags(department);

    return (await Promise.all(departments.map(async d => (await firebase.default.firestore().collection('subcate')
        .where('groupId', '==', d?.id ?? '')
        .get())
        .docs
        .map(doc => doc.data())))).reduce((prev, curr) => prev.concat(curr), []);
  }

  static async getDepartmentWearerIds(department: Department): Promise<string[]> {
    if(department.id === _RootTagId) {
      return Distinct((await this.getDepartmentSubcates(department)).map(subcate => subcate.id));
    }

    const departments = getSameAndUserTags(department);
    return Distinct((await Promise.all(departments.map(async department => {
      return (await this.getDepartmentSubcates(department)).map(subcate => subcate.id);
    }))).reduce((prev, curr) => prev.concat(curr), []));
  }

  static async getDepartmentDevices(department: Department | null): Promise<any[]> {
    if(department === null) {
        return (await firebase.default.firestore().collection('devices')
        .get())
        .docs
        .map(doc => doc.data());
    }

    const wearerIds = await this.getDepartmentWearerIds(department);

    return (await Promise.all(wearerIds.map(async wearerId => {
        const device = (await firebase.default.firestore().collection('devices')
        .where('wearerId', '==', wearerId)
        .get())
        .docs
        .map(doc => doc.data());
        return device;
    }))).reduce((prev, curr) => prev.concat(curr), []);
  }

  static async getDepartmentManagers(department: Department): Promise<any[]> {
    if(department === null) {
        return (await firebase.default.firestore().collection('managers')
        .get())
        .docs
        .map(doc => doc.data());
    }

    return (await firebase.default.firestore().collection('managers')
    .where('groupId', '==', department.id)
    .get())
    .docs
    .map(doc => doc.data());
  }

  static async readDepartments(): Promise<Department[]> {
    return (await firebase.default.firestore().collection("departments").get()).docs.map(doc => doc.data()) as Department[];
  }
}
