import * as firebase from 'firebase/app';
import { promise } from 'protractor';

export interface AlermPosition {
  deviceId: string;
  latitude: number;
  longitude: number;
  alertName: string;
  timestamp: any;
  time: Date;
}

export class MapRepository {
  public static async getAlermsPositions(deviceId: string): Promise<AlermPosition[]> {
    const db = firebase.default.firestore();
    const deviceData = (await db.collection('devices').doc(deviceId).get())?.data() ?? null;
    if (!deviceData) {
      return [];
    }

    const alerts = deviceData.alertsHistory.slice().reverse().slice(0, 5)
      .map(a => {
        let x = { } as AlermPosition;
        const columns = a.split('_');
        try {
          const time = new Date(columns[0]);
          if(isNaN(time.getTime())) {
            return null;
          }

          x = {
            deviceId,
            latitude: undefined,
            longitude: undefined,
            alertName: columns[1],
            timestamp: time,
            time,
          };
          return x;
        } catch {
          return null;
        }
      }).filter(a => a !== null) as AlermPosition[];

      const alermPostions = (await Promise.all(alerts.map(async a => {
        const twoMinutesLater = new Date(a.time.getTime() + 2 * 60 * 1000);
        const sensorDatas = (await db.collection(deviceId).where('timestamp', '>', twoMinutesLater)
          .orderBy('timestamp', 'asc').limit(5).get())
          .docs?.map(d => d.data()) ?? null;

        if (!sensorDatas) { return null; }
        const getTimeDiff = (s: any) => Math.abs(a.time.getTime() - s.startTimestamp.toDate().getTime());
        const sensorData = sensorDatas.filter(s => s.location).sort((v1, v2) =>  getTimeDiff(v1) - getTimeDiff(v2))[0] ?? null;

        if(sensorData) {
          const location = sensorData.location;
          console.log({timeDiffMiliSeconds: getTimeDiff(sensorData)});
          return {
            ...a,
            latitude: location.latitude,
            longitude: location.longitude,
          };
        } else {
          return null;
        }
      }))).filter(a => a !== null) as AlermPosition[];

      return alermPostions;
  }
}
