import * as firebase from 'firebase/app';

export interface Tag {
    id: string;
    name: string;
    children: Tag[];
}

export const _RootTagId = 't0';

const createRandaomId = async (charCount: number) => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charsLength = chars.length;
    let id = '';
    for (let i = 0; i < charCount; i++) {
        id += chars[Math.floor(Math.random() * charsLength)];
    }
    return id;
};

export const readTag = async (): Promise<Tag> => {
    const tag = ((await firebase.default.firestore().collection('tags').doc('t0').get())?.data() ?? null) as Tag | null;
    if(tag === null) {
        await firebase.default.firestore().collection('tags').doc('t0').set(sampleTag);
        return sampleTag;
    } else {
        return tag;
    }
};

export const readTagByIdRaw = (parent: Tag, id: string): Tag | null => {
    if (parent.id === id) {
        return parent;
    }

    for (const child of parent.children) {
        const result = readTagByIdRaw(child, id);
        if (result !== null) {
            return result;
        }
    }

    return null;
};

export const readTagById = async (parent: Tag | null, id: string): Promise<Tag | null> => {
    if (parent === null) {
        parent = await readTag();
    }

    if (parent.id === id) {
        return parent;
    }

    for (const child of parent.children) {
        const result = readTagByIdRaw(child, id);
        if (result !== null) {
            return result;
        }
    }

    return null;
};

const updateTag = (parent: Tag, tag: Tag): Tag => {
    if(tag.id === parent.id) {
        return tag;
    } else {
        return {
            ...parent,
            children: parent.children.map(child => updateTag(child, tag)),
        };
    }
}

const updateTagRaw = async (tag: Tag) => {
    const root = await readTag();
    const newTag = await updateTag(root, tag);

    console.log({newTag});

    await firebase.default.firestore().collection('tags').doc('t0').set(newTag, { merge: true });
}

export const updateTagName = async (tag: Tag, name: string) => {
    const target = await readTagById(null, tag.id);

    const newTag = {
        ...target,
        name,
    };

    await updateTagRaw(newTag);
};

export const addTag = async (parent: Tag, name: string) => {
    parent = await readTagById(null, parent.id);

    const newTag: Tag = {
        id: await createRandaomId(10),
        name,
        children: [],
    };

    const newParent = {
        ...parent,
        children: [...parent.children, newTag],
    };

    await updateTagRaw(newParent);
};

export const deleteTag = async (parent: Tag, tag: Tag) => {
    parent = await readTagById(null, parent.id);

    const newParent = {
        ...parent,
        children: parent.children.filter(child => child.id !== tag.id),
    };

    await updateTagRaw(newParent);
};

export const moveDownTag = async (parent: Tag, tag: Tag) => {
    parent = await readTagById(null, parent.id);

    const index = parent.children.findIndex(child => child.id === tag.id);
    if(index === -1 || index === parent.children.length - 1) {
        return;
    }

    const newChildren = [...parent.children];
    [newChildren[index], newChildren[index + 1]] = [newChildren[index + 1], newChildren[index]];

    const newParent = {
        ...parent,
        children: newChildren,
    };

    await updateTagRaw(newParent);
};

export const moveUpTag = async (parent: Tag, tag: Tag) => {
    parent = await readTagById(null, parent.id);

    const index = parent.children.findIndex(child => child.id === tag.id);
    if(index === -1 || index === 0) {
        return;
    }

    const newChildren = [...parent.children];
    [newChildren[index], newChildren[index - 1]] = [newChildren[index - 1], newChildren[index]];

    const newParent = {
        ...parent,
        children: newChildren,
    };

    await updateTagRaw(newParent);
};

export type TagTreeText = Tag & { text: string };
export const getTagTreeList = (tag: TagTreeText, depath: number): TagTreeText[] => {
    return [
        {
            ...tag,
            text: '　'.repeat(depath) + tag.name
        },
        ...tag.children.map(child => getTagTreeList({ ...child, text: ''}, depath + 1)).reduce((prev, curr) => prev.concat(curr), [])
    ];
};

export const getSameAndUserTags = (tag: Tag): Tag[] => {
    return [
        tag,
        ...tag.children.map(child => getSameAndUserTags(child)).reduce((prev, curr) => prev.concat(curr), [])
    ];
};

export const getTagList = (tag: Tag): Tag[] => {
    return [
        tag,
        ...tag.children.map(child => getTagList(child)).reduce((prev, curr) => prev.concat(curr), [])
    ];
}


export const sampleTag: Tag = {
id: 't0',
name: "代表",
children: [
{
  id: 't1',
  name: "開発",
  children: [
    {
      id: 't2',
      name: "あんしんミマモルナビ開発チーム",
      children: [
        {
          id: 't4',
          name: "WearOsアプリチーム",
          children: []
        },
        {
          id: 't5',
          name: "管理画面Webチーム",
          children: []
        },
        {
          id: 't6',
          name: "バックエンドチーム",
          children: []
        },
      ]
    },
    {
      id: 't3',
      name: "SmiRe開発チーム",
      children: [
        {
          id: 't7',
          name: "クライアントWebチーム",
          children: []
        },
        {
          id: 't8',
          name: "管理画面Webチーム",
          children: []
        },
        {
          id: 't9',
          name: "バックエンドチーム",
          children: []
        },
      ]
    }
  ]
}
],
};