export class AlertEmojiRepository {
    static getEmoji(alertName: string): string {
        switch (alertName) {
            case '暑さ指数':
                return '🌡️';
            case '心拍モニタリング':
                return '❤️';
            case '熱中症注意アラート':
                return '🫠';
            case '熱中症危険アラート':
                return '🥵';
            case '疲労アラート':
                return '😵';
            case '眠気モニタリング':
                return '💤';
            case '強い眠気モニタリング':
                return '💤';
            case '強い眠気アラート':
                return '💤';
            case '転倒モニタリング':
                return '⚠️';
            default:
                return '❓';
        }
    }
}
