

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

import * as firebase from 'firebase/app';
import {
  PeriodicElement,
  Transaction,
} from "../../assets/table-data";
import { AlertViewService } from 'src/app/services/alert-view.service';
import { SessionService } from 'src/app/service/session.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { readTag, sampleTag, Tag } from 'src/app/repository/tag';

export type Department = Tag;

type Element = PeriodicElement | Transaction;
@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.managerlist);
  departments: Department[] = [];
  tag: Tag | null = null;
  departmentId = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
    private session: SessionService,
    private afAuth: AngularFireAuth,
    private alertService: AlertViewService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.departmentId = this.activatedRoute.snapshot.paramMap.get('department');
    session.isAdminDepaertment().then(isAdmin => {
      if(!isAdmin) {
        this.router.navigate(['/']);
      }
    });
    this.readDeparrtment();
    this.initializeTag();
  }

  async initializeTag() {
    (await firebase.default.firestore().collection('tags').doc('t0')).onSnapshot(async (doc) => {
      this.tag = doc.data() as Tag;
    });
  }

  async editDepartment(d: Department | null) {
    const { value: formValues } = await Swal.fire({
      title: this.lang.show('部署の編集'),
      html:
        '<div style="display: grid; grid-template-columns: 5rem 1fr;">' +
        `<div style="padding-top: 2rem;">${this.lang.show("名前")}</div><div><input id="swal-input1" class="swal2-input" value="${d?.name ?? ''}"></div>` +
        '</div>',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: async () => {
        const data: Department = {
          id: d?.id ?? "",
          name: document.getElementById('swal-input1')["value"],
          children: d?.children ?? [],
        };

        if (data.name.length == 0) {
          alert(this.lang.show("名前の項目が未入力です"));
          return false;
        }
        return data;
      },
    })

    if(!formValues) return;

    if (d === null && formValues) {
        const newId = firebase.default.firestore().collection("departments").doc().id;
        formValues["id"] = newId;
        await firebase.default.firestore().collection("departments").doc(newId).set(formValues, { merge: true });
        Swal.fire(this.lang.show("登録を完了しました"));
    } else {
      await firebase.default.firestore().collection("departments").doc(d.id).set(formValues, { merge: true });
      Swal.fire(this.lang.show("更新を完了しました"));
    }

    await this.readDeparrtment();
  }

  async deleteDepartment(d: Department) {
    await firebase.default.firestore().collection("departments").doc(d.id).delete();
    this.departments = this.departments.filter(department => department.id !== d.id);
  }

  async readDeparrtment() {
    this.departments = (await firebase.default.firestore().collection("departments").get()).docs.map(doc => doc.data()) as Department[];
  }

  ngOnInit(): void {
  }
}