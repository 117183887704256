import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { ChatListComponent } from './pages/chat-list/chat-list.component';
import { DeviceListComponent } from './pages/device-list/device-list.component';
import { ItemDetailChartComponent } from './pages/item-detail-chart/item-detail-chart.component';
import { LoginComponent } from './pages/login/login.component';
import { ManagerListComponent } from './pages/manager-list/manager-list.component';
import { MapComponent } from './pages/map/map.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { NettyushoRiskComponent } from './pages/nettyusho-risk/nettyusho-risk.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { ReadQrCodeComponent } from './pages/read-qr-code/read-qr-code.component';
import { SummaryDetailComponent } from './pages/summary-detail/summary-detail.component';
import { AlertSummaryComponent } from './pages/alert-summary/alert-summary.component';
import { AlertListComponent } from './pages/alert-list/alert-list.component';
import { NettyushoRiskChartComponent } from './pages/nettyusho-risk-chart/nettyusho-risk-chart.component';
import { HirouListComponent } from './pages/hirou-list/hirou-list.component';
import { StressDetailChartComponent } from './pages/stress-detail-chart/stress-detail-chart.component';
import { ConditionListComponent } from './pages/condition-list/condition-list.component';
import { ConditionDetailComponent } from './pages/condition-detail/condition-detail.component';
import { ProjectListComponent } from './pages/project-list/project-list.component';
import { MyDeviceComponent } from './pages/my-device/my-device.component';
import { MyConditionComponent } from './pages/my-condition/my-condition.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { DepartmentComponent } from './pages/department/department.component';

const nagareDomains = [
  'https://mnavi-super-admin-nagare.web.app',
];

const jmarDomains = [
  'http://localhost:8100',
  'https://mimamorunavi-admin.web.app',
  'https://mimamorunavi.com'
];

export const isNagare = nagareDomains.some(domain => window.location.href.indexOf(domain) === 0);
export const isJmar = jmarDomains.some(domain => window.location.href.indexOf(domain) === 0);

const routes: Routes = isNagare ? [
  {
    path: '',
    redirectTo: 'project-list',
    pathMatch: 'full', data: { animation: 'Page1' },
  },
  {
    path: 'login', component: LoginComponent,
    pathMatch: 'full', data: { animation: 'Page1' }
  },
  {
    path: 'device-list',
    redirectTo: 'project-list',
    pathMatch: 'full', data: { animation: 'Page1' },
  },
  {
    path: 'terms', component: TermsComponent,
    pathMatch: 'full', data: { animation: 'Page2' }
  },
  {
    path: 'policy', component: PolicyComponent,
    pathMatch: 'full', data: { animation: 'Page2' }
  },
  {
    path: 'project-list', component: ProjectListComponent,
    pathMatch: 'full', data: { animation: 'Page2' }, canActivate: [AuthGuard]
  },
  {
    path: '**', component: PageNotFoundComponent
  },
] : [
  {
    path: '',
    redirectTo: 'TOP/device-list',
    pathMatch: 'full', data: { animation: 'Page1' },
  },
  {
    path: 'department', component: DepartmentComponent,
    pathMatch: 'full', data: { animation: 'Page1' },
  },
  {
    path: ':department/device-list', component: DeviceListComponent,
    pathMatch: 'full', data: { animation: 'Page1' }, canActivate: [AuthGuard],
  },
  {
    path: 'readQrCode', component: ReadQrCodeComponent,
    pathMatch: 'full', data: { animation: 'Page1' }
  },
  {
    path: 'login', component: LoginComponent,
    pathMatch: 'full', data: { animation: 'Page1' }
  },
  {
    path: 'user-profile/:id', component: UserProfileComponent,
    pathMatch: 'full', data: { animation: 'Page1' }, canActivate: [AuthGuard],
  },
  {
    path: 'item-detail/:id', component: ChatListComponent,
    pathMatch: 'full', data: { animation: 'Page1' }, canActivate: [AuthGuard],
  },
  {
    path: 'my-device/:lang/:id', component: MyDeviceComponent,
    pathMatch: 'full', data: { animation: 'Page1' },
  },
  {
    path: 'my-condition/:lang/:id', component: MyConditionComponent,
    pathMatch: 'full', data: { animation: 'Page1' },
  },
  {
    path: 'summary-detail/:id', component: SummaryDetailComponent,
    pathMatch: 'full', data: { animation: 'Page2' }, canActivate: [AuthGuard],
  },
  {
    path: 'item-detail-chart/:id', component: ItemDetailChartComponent,
    pathMatch: 'full', data: { animation: 'Page2' }, canActivate: [AuthGuard],
  },
  {
    path: 'nettyusho-risk-chart/:id', component: NettyushoRiskChartComponent,
    pathMatch: 'full', data: { animation: 'Page2' }, canActivate: [AuthGuard],
  },
  {
    path: ':department/nettyusho-risk', component: NettyushoRiskComponent,
    pathMatch: 'full', data: { animation: 'Page1' }, canActivate: [AuthGuard],
  },
  {
    path: 'stress-detail-chart/:id', component: StressDetailChartComponent,
    pathMatch: 'full', data: { animation: 'Page2' }, canActivate: [AuthGuard],
  },


  {
    path: 'nettyusho-risk-chart/:id', component: NettyushoRiskChartComponent,
    pathMatch: 'full', data: { animation: 'Page2' }, canActivate: [AuthGuard],
  },
  {
    path: 'nettyusho-risk', component: NettyushoRiskComponent,
    pathMatch: 'full', data: { animation: 'Page1' }, canActivate: [AuthGuard],
  },
  {
    path: 'stress-detail-chart/:id', component: StressDetailChartComponent,
    pathMatch: 'full', data: { animation: 'Page2' }, canActivate: [AuthGuard],
  },
  {
    path: 'user-list',
    redirectTo: 'TOP/user-list',
    pathMatch: 'full', data: { animation: 'Page1' },
  },
  {
    path: ':department/user-list', component: UserListComponent,
    pathMatch: 'full', data: { animation: 'Page1', tableName: 'a' }, canActivate: [AuthGuard],
  },
  {
    path: ':department/alert-list', component: AlertListComponent,
    pathMatch: 'full', data: { animation: 'Page1', tableName: 'a' }, canActivate: [AuthGuard],
  },
  {
    path: 'manager-list',
    redirectTo: 'TOP/manager-list',
    pathMatch: 'full', data: { animation: 'Page1' },
  },
  {
    path: ':department/manager-list', component: ManagerListComponent,
    pathMatch: 'full', data: { animation: 'Page1', tableName: 'a' }, canActivate: [AuthGuard],
  },
  {
    path: 'device-list', redirectTo: 'TOP/device-list',
    pathMatch: 'full', data: { animation: 'Page1', tableName: 'a' },
  },
  {
    path: ':department/alert-summary', component: AlertSummaryComponent,
    pathMatch: 'full', data: { animation: 'Page1', tableName: 'a' }, canActivate: [AuthGuard],
  },
  {
    path: 'alert-summary', component: AlertSummaryComponent,
    pathMatch: 'full', data: { animation: 'Page1', tableName: 'a' }, canActivate: [AuthGuard],
  },
  {
    path: 'hirou-list', component: HirouListComponent,
    pathMatch: 'full', data: { animation: 'Page1', tableName: 'a' }, canActivate: [AuthGuard],
  },
  {
    path: 'condition-list',
    redirectTo: 'TOP/condition-list',
    pathMatch: 'full', data: { animation: 'Page1' }
  },
  {
    path: ':department/condition-list', component: ConditionListComponent,
    pathMatch: 'full', data: { animation: 'Page1', tableName: 'a' }, canActivate: [AuthGuard],
  },
  {
    path: 'condition-detail/:id', component: ConditionDetailComponent,
    pathMatch: 'full', data: { animation: 'Page2', tableName: 'a' }, canActivate: [AuthGuard],
  },

  {
    path: 'map/:id', component: MapComponent,
    pathMatch: 'full', data: { animation: 'Page2' }, canActivate: [AuthGuard],
  },
  {
    path: 'terms', component: TermsComponent,
    pathMatch: 'full', data: { animation: 'Page2' }
  },
  {
    path: 'policy', component: PolicyComponent,
    pathMatch: 'full', data: { animation: 'Page2' }
  },
  {
    path: 'page-not-found', component: PageNotFoundComponent,
    pathMatch: 'full', data: { animation: 'Page2' }
  },
  {
    path: ':department/summary', component: SummaryComponent,
    pathMatch: 'full', data: { animation: 'Page1' }, canActivate: [AuthGuard],
  },
  {
    path: 'project-list', component: ProjectListComponent,
    pathMatch: 'full', data: { animation: 'Page2' }, canActivate: [AuthGuard]
  },
  {
    path: '**', component: PageNotFoundComponent
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
