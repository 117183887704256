import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { getLangFromComponent } from 'src/app/lang/logic';
import { AlertEmojiRepository } from 'src/app/repository/AlertEmojiRepository';
import DeviceRepository from 'src/app/repository/DeviceRepository';
import { MapRepository } from 'src/app/repository/MapRepository';
import { getToday, get24HoursAgo } from 'src/app/repository/Tiime';
declare var google;

function createEmojiIcon(emoji: string): string {
  const canvas = document.createElement("canvas");
  canvas.width = 90; // 幅1.5倍（60 → 90）
  canvas.height = 120; // 高さ1.5倍（80 → 120）
  const ctx = canvas.getContext("2d")!;

  // ① 背景（白いピン型）
  ctx.fillStyle = "white";
  ctx.beginPath();
  ctx.moveTo(45, 110); // 下の尖り
  ctx.arc(45, 45, 37.5, Math.PI, 2 * Math.PI); // 上の円部分
  ctx.closePath();
  ctx.fill();
  ctx.strokeStyle = "gray"; // 枠線
  ctx.lineWidth = 3;
  ctx.stroke();

  // ② 絵文字を描画（上部の円の中央に配置）
  ctx.font = "45px sans-serif"; // 文字サイズも1.5倍
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.fillText(emoji, 45, 45);

  return canvas.toDataURL();
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.map)


  // Map関係
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  map: any;
  markers: any[] = [];

  macad = "";
  public device: Observable<any[]>;

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private router: Router

  ) {

    this.macad = (this.route.snapshot.paramMap.get('id'));



  }

  ngOnInit() {
    console.log('ngOnInitです');



    this.device = this.db
      .collection<any>(this.macad, ref => { return ref.where('timestamp', '>=', get24HoursAgo()).orderBy('timestamp', 'desc').limit(1); })
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(action => {
          var data = action.payload.doc.data();

          const shoudNotVisible = DeviceRepository.getIsLocationVisible(data) === false;

          data["time"] = data.timestamp.toDate();
          data["timeString"] = data.timestamp.toDate().toLocaleString(this.lang.show('ja'));

          if (shoudNotVisible) {
            data.location = {
              latitude: 0,
              longitude: 0
            }
          }

          this.loadMap(data).then(async (ret) => {
            if (this.markers != undefined) {
              this.markers.filter(e => e != undefined).forEach(marker => {
                marker.setMap(null);
              });
              this.markers = [];
            }

            const alertMarkers = await this.getAlermPosition();

            this.markers = [
              new google.maps.Marker({
                position: { lat: data.location.latitude, lng: data.location.longitude },
                map: this.map,
                icon: {
                  scaledSize: new google.maps.Size(30, 30), // scaled size
                  origin: new google.maps.Point(0, 0), // origin
                  anchor: new google.maps.Point(0, 0) // anchor
                },
                
              }),
            ...alertMarkers
            ];
          });


          console.log(data);
          return data;
        })));

    this.device.subscribe();

  }

  async getAlermPosition(): Promise<any[]> {
    const positions = (await MapRepository.getAlermsPositions(this.macad)).reverse();
    console.log({positions});
    return positions.map((p, index) => {
      const position = { lat: p.latitude, lng: p.longitude };
      const emoji = AlertEmojiRepository.getEmoji(p.alertName);
      const emojiIcon = createEmojiIcon(emoji);
      const timeText = this.lang.showTime(p.time);
      const langAlertName = this.lang.show(p.alertName);
    
      const marker = new google.maps.Marker({
        position,
        map: this.map,
        title: 'クリックで詳細表示',
        icon: {
          url: emojiIcon,
          scaledSize: new google.maps.Size(45, 60), // 1.5倍に拡大
          anchor: new google.maps.Point(22.5, 60)
        },
        zIndex: index
      });

      const infoWindow = new google.maps.InfoWindow({
        content: `<div>${timeText}<div><p>${langAlertName || ''}</p>`
      });

      marker.addListener('click', () => {
        infoWindow.open(this.map, marker);
      });
    });
  }

  ionViewDidEnter() {
    // this.loadMap();
  }

  // Initialize a blank map
  loadMap(data) {
    return new Promise((resolve) => {
      let latLng = new google.maps.LatLng(data.location.latitude, data.location.longitude);
      let mapOptions = {
        center: latLng,
        zoom: 10,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
      resolve(true);
    });
  }


}
