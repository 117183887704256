import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import * as firebase from 'firebase/app';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  Project,
  ProjectStatus,
  ProjectStatusList,
  ProjectStatusType,
} from 'src/app/model';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { isNagare } from 'src/app/app-routing.module';
import { SendMailAPI } from 'src/Api/SendMail';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css'],
})
export class ProjectListComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.projectlist);
  public isNagare = isNagare;

  public items: Observable<Project[]>;
  public searchText: string = '';

  public statusOptions = ProjectStatusList.map((status) => {
    return { value: status, label: this.lang.show(status) };
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
    private alertService: AlertViewService
  ) {
    this.initialize();
  }

  async initialize() {
    const word = this.searchText.toLowerCase();

    this.items = this.db
      .collection<Project>('projects')
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions
            .map((action) => {
              const data = action.payload.doc.data();

              return data.isOpened
                ? {
                    ...data,
                    createdAt: (data?.createdAt as any)?.toDate() ?? null,
                    creator: data.createdByNagare === true ? 'Nagare' : '',
                    status: data.status ?? ProjectStatus.testing,
                  }
                : null;
            })
            .filter(
              (x) =>
                x !== null &&
                x.isDeleted !== true &&
                (!isNagare || x.createdByNagare === true)
            )
            .filter(
              (x) =>
                !word ||
                x.displayName.toLocaleLowerCase().includes(word) ||
                x.companyCode.toLowerCase().includes(word) ||
                x.creator.toLowerCase().includes(word) ||
                x.URL.toLowerCase().includes(word)
            )
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
        })
      );
  }

  async searchProject() {
    this.initialize();
  }

  async onStatusSelectionChange(event: Event, project: Project) {
    const selectElement = event.target as HTMLSelectElement;
    const status = selectElement.value as ProjectStatusType;

    const db = firebase.default.firestore();
    await db.collection('projects').doc(project.id).update({ status });
    this.alertService.showGoodAlert(this.lang.show('更新しました'));
  }

  getDate(date: Date | null): string {
    if (date === null) {
      return '';
    }
    return (
      date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
    );
  }

  deleteProject(id) {
    alert('誤操作防止のため、プロジェクトの削除ボタンの機能は無効化しています');
    return;

    this.alertService.showYesNoAlertDelete('プロジェクト').then((ret) => {
      if (ret) {
        // firebase.default.firestore().collection("projects").doc(p.id).set({ isDeleted: true }, { merge: true });
      }
    });
  }

  async changeDisplayName(p: Project) {
    const value = await Swal.fire({
      title: this.lang.show('プロジェクトの名前の編集'),
      html: `<div style="display: grid; grid-template-columns: 5.6rem 1fr;  grid-row-gap: 2rem;"">
        <div style="padding-top: 2rem;">${this.lang.show(
          'プロジェクトの名前'
        )}</div><div><input id="swal-name" type="datetime" class="swal2-input" value="${
        p.displayName ?? ''
      }" readonly></div>
        <div style="padding-top: 2rem;">${this.lang.show(
          '新しいプロジェクトの名前'
        )}</div><div><input id="new-displayName" class="swal2-input" value=""></div>
        </div>`,
      confirmButtonText: this.lang.show('修正'),
      focusConfirm: false,
      showCancelButton: false,
      preConfirm: async () => {
        const getValueById = (id: string): string =>
          document.getElementById(id)['value'];
        const displayName = getValueById('new-displayName');

        await firebase.default
          .firestore()
          .collection('projects')
          .doc(p.id)
          .update({ displayName });

        return true;
      },
    });

    const isSuccess = value.value === true;
    if (isSuccess) {
      this.alertService.showGoodAlert(
        this.lang.show('プロジェクト名を修正しました')
      );
    }
  }

  async changeUrl(p: Project) {
    const value = await Swal.fire({
      title: this.lang.show('ドメイン変更のリクエスト'),
      html: `<div style="display: grid; grid-template-columns: 5.6rem 1fr;  grid-row-gap: 2rem;"">
        <div style="padding-top: 2rem;">${this.lang.show(
          '現在のドメイン'
        )}</div><div><input id="swal-name" type="datetime" class="swal2-input" value="${
        p.URL ?? ''
      }" readonly></div>
        <div style="padding-top: 2rem;">${this.lang.show(
          '新しいドメイン'
        )}</div><div><input id="new-domain" class="swal2-input" value=""></div>
        </div>`,
      confirmButtonText: this.lang.show('リクエスト'),
      focusConfirm: false,
      showCancelButton: false,
      preConfirm: async () => {
        const getValueById = (id: string): string =>
          document.getElementById(id)['value'];

        const rawData = {
          requestDomainName: getValueById('new-domain'),
        };

        return rawData;
      },
    });

    if (
      !value.value?.requestDomainName ||
      (value.value?.requestDomainName.length ?? 0) === 0
    ) {
      return;
    }

    const newDomain = value.value.requestDomainName;
    const currentDomain = p.URL;
    const requestBody = {
      currentDomain,
      newDomain,
    };
    const isSuccess = await firebase.default
      .firestore()
      .collection('domain-requests')
      .add(requestBody);

    if (isSuccess) {
      this.alertService.showGoodAlert(
        this.lang.show(
          'リクエストを送信しました。完了次第連絡いたしますのでしばらくお待ち下さい。'
        )
      );
    } else {
      this.alertService.showErrorAlert(
        this.lang.show('リクエストの送信に失敗しました')
      );
    }
  }

  async showProject(p: Project) {
    const { value: formValues } = await Swal.fire({
      title: this.lang.show('プロジェクトの情報'),
      html: `<div style="display: grid; grid-template-columns: 5.6rem 1fr;  grid-row-gap: 2rem;"">
        <div style="padding-top: 2rem;">${this.lang.show(
          'プロジェクトの名前'
        )}</div><div><input id="swal-name" class="swal2-input" value="${
        p.displayName
      }" readonly></div>
        <div style="padding-top: 2rem;">${this.lang.show(
          '管理画面コード'
        )}</div><div><input id="swal-name" class="swal2-input" value="${
        p.companyCode
      }" readonly></div>
        <div style="padding-top: 2rem;">${this.lang.show(
          '作成日'
        )}</div><div><input id="swal-name" type="datetime" class="swal2-input" value="${
        p.createdAt ?? ''
      }" readonly></div>
        <div style="padding-top: 2rem;">${this.lang.show(
          'ログインアカウント'
        )}</div><div><input id="swal-name" type="datetime" class="swal2-input" value="${
        p.masterLoginID ?? ''
      }" readonly></div>
        <div style="padding-top: 2rem;">${this.lang.show(
          'ログインパスワード'
        )}</div><div><input id="swal-name" type="datetime" class="swal2-input" value="${
        p.masterLoginPW ?? ''
      }" readonly></div>
        <div style="padding-top: 2rem;">${this.lang.show(
          'URL'
        )}</div><div><input id="swal-name" type="datetime" class="swal2-input" value="${
        p.URL ?? ''
      }" readonly></div>
        </div>`,
      focusConfirm: false,
      showCancelButton: false,
      preConfirm: () => {
        return {};
      },
    });
  }

  async showLoginPage(p: Project) {
    window.open(
      p.URL + '/login?id=' + p.masterLoginID + '&pass=' + p.masterLoginPW,
      '_blank',
      'location=yes'
    );
  }

  async getFirstUnopenedProject(): Promise<Project | null> {
    const projects: Project[] = (
      await firebase.default
        .firestore()
        .collection('projects')
        .orderBy('name')
        .get()
    ).docs
      .map((doc) => {
        return {
          ...(doc.data() as Project),
          createdAt: (doc.data().createdAt as any)?.toDate() ?? null,
        };
      })
      .filter((x) => x.isOpened !== true);

    if (projects.length === 0) {
      return null;
    }

    console.log(projects);

    return {
      ...projects[0],
      id: projects[0].id,
    } as Project;
  }

  async showAddProjectAlert() {
    const checkFirstProject = async () => {
      const proj = await this.getFirstUnopenedProject();
      if (proj === null) {
        alert(this.lang.show('空のプロジェクトがありません'));
        return null;
      }

      return proj;
    };

    if ((await checkFirstProject()) === null) {
      return;
    }

    const { value: formValues } = await Swal.fire({
      title: this.lang.show('プロジェクトを登録'),
      html: `<div>
        <div style="padding-top: 2rem;">${this.lang.show(
          'プロジェクトの名前'
        )}</div><div><input id="swal-name" class="swal2-input" value=""></div>
        <div style="padding-top: 2rem;">${this.lang.show(
          '管理画面コード'
        )}</div><div><input type="tel" id="swal-companyCode" class="swal2-input" value=""></div>
        </div>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: async () => {
        const getValueById = (id: string): string =>
          document.getElementById(id)['value'];

        const rawData = {
          displayName: getValueById('swal-name'),
          companyCode: getValueById('swal-companyCode'),
          createdAt: new Date(),
          isOpened: true,
        };

        const data = isNagare
          ? {
              ...rawData,
              createdByNagare: isNagare,
            }
          : rawData;

        if (data.displayName.length === 0) {
          alert(this.lang.show('プロジェクトの名称の項目が未入力です'));
          return false;
          /*
          } else if (data.domainName.length === 0) {
            alert("ドメインの名称の項目が未入力です");
            return false;
          */
        } else if (
          data.companyCode.length !== 4 &&
          isNaN(Number(data.companyCode))
        ) {
          alert(
            this.lang.show('管理画面コードは4桁の数字でなければなりません')
          );
          return false;
        }

        // 全角英数字を半角に変換
        function toHalfWidth(str) {
          str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
          });
          return str;
        }
        data.companyCode = toHalfWidth(data.companyCode);

        if (
          (
            await firebase.default
              .firestore()
              .collection('projects')
              .where('companyCode', '==', data.companyCode)
              .get()
          ).docs.length > 0
        ) {
          alert(this.lang.show('管理画面コードが重複しています'));
          return false;
        }

        return data;
      },
    });

    if (formValues) {
      const firstProject = await checkFirstProject();
      const updateDocument: Project = {
        ...firstProject,
        ...formValues,
      };
      console.log(updateDocument);
      await firebase.default
        .firestore()
        .collection('projects')
        .doc(firstProject.id)
        .set(updateDocument, { merge: true });
      this.alertService.showLoading();
      setTimeout(() => {
        this.alertService.dismiss();
        Swal.fire(this.lang.show('プロジェクトの登録を完了しました'));
      }, 6000);
    }
  }

  ngOnInit(): void {}
}
