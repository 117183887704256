import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Session } from 'src/app/class/session/session';
import { User } from 'src/app/class/user/user';

import { SessionService } from 'src/app/service/session.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { Storage } from '@ionic/storage-angular';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { Tag } from 'src/app/repository/tag';

let openTagId = '';

@Component({
  selector: 'app-tag-treee',
  templateUrl: './tag-treee.component.html',
  styleUrls: ['./tag-treee.component.css']
})
export class TagTreeComponent implements OnInit {
  lang = getLangFromComponent(ComponentLabel.defaultheader);
  @Input() selection: Tag | null = null;
  @Input() parent: Tag | null = null;
  @Input() tag: Tag;
  @Input() depth = 0;
  isOpen = true;

  subDirectory = '';


  constructor(
    private session: SessionService,
    private router: Router,
    private activedRoute: ActivatedRoute, 
  ) {
  }

  async moveTag() {
    this.router.navigate([this.tag.id + '/' + this.subDirectory]);
  }

  isEdge(): boolean {
    return (this.tag?.children?.length ?? 1) === 0;
  }

  isOpenMenu() {
    return openTagId === this.tag.id;
  }

  onToggleOpen(event: MouseEvent) {
    this.isOpen = !this.isOpen && !this.isEdge();
    event.stopPropagation();
  }

  onToggleMenu(event: MouseEvent) {
    if(this.isOpenMenu()) {
      openTagId = '';
    } else {
      openTagId = this.tag.id;
    }

    event.stopPropagation();
  }

  ngOnInit(): void {
    this.isOpen = !this.isEdge();

    const deparmentId = this.activedRoute.snapshot.paramMap.get('department') ?? '';
    const url = this.router.url;
    this.subDirectory = url.split('/' + deparmentId + '/')[1];
  }
}
