import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Password } from 'src/app/class/session/session';
import Validation from 'src/app/class/validations/validation';
import { ComponentLabel, LangType } from 'src/app/lang/dictionary';
import { getLangFromComponent, getLanguage, setLanguage } from 'src/app/lang/logic';
import { getQueryParams } from 'src/app/repository/UrlUtility';
import { SessionService } from 'src/app/service/session.service';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.login)
  public langCode: string = "en";


  form: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    private alertService: AlertViewService,
  ) { }

  ngOnInit(): void {
    this.langCode = getLanguage();
    const email = getQueryParams('id') ?? '';
    const pass = getQueryParams('pass') ?? '';

    this.form = this.formBuilder.group(
      {

        email: [email, [Validators.required, Validators.email]],
        password: [
          pass,
          [
            Validators.required
          ]
        ],
      }
    );



  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }


    const account = new Password(this.form.value);
    this.sessionService.login(account);
    if (environment.debug) {
      console.log(JSON.stringify(this.form.value, null, 2));
    }
  }

  setLang(lang: LangType): void {
    setLanguage(lang);
    location.reload();
  }

  login(): void {

    const account = new Password(this.form.value);
    this.sessionService.login(account);
    if (environment.debug) {
      console.log(JSON.stringify(this.form.value, null, 2));
    }
  }

}
