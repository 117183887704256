import Swal from "sweetalert2";
import { LangType, getLangDictionary, _Languages, ComponentLabel } from "./dictionary"
import { toShortDateEN, toShortDateES, toShortDateJA } from "../repository/DateHelper";

function getNvigatorLanguage(): LangType {
  // ブラウザのデフォルトの言語を取得

  const lang = navigator.languages[0];
  if (!lang) return "ja";

  if (lang.startsWith("ja")) return "ja";
  if (lang.startsWith("en")) return "en";
  if (lang.startsWith("es")) return "es";
  return "ja";
}

function getStorageLaunguage(): string {
  const lang = localStorage.getItem("lang") as string;
  return lang ?? "";
}

export function getLanguage(): string {
  const lang = getStorageLaunguage();

  return lang ?? getNvigatorLanguage();
}

let language = getLanguage();

export function setOverrideTempLaunguage(lang: LangType) {
  language = lang;
}

export function setLanguage(lang: LangType) {
  localStorage.setItem("lang", lang);
  language = lang;
}

const getLang = () => language;

const Lang = {
  show: (component: string, label: string): string => {
    const dic = getLangDictionary();
    const text = dic[label];
    if (text) {
      return text[getLang()];
    } else {
      console.error(`Translation not found for ${component}.${label}`);
      return "";
    }
  },
  showArray: (component: string, labels: string[]): string[] => labels.map(label => Lang.show(component, label)),
  showNoTanmatsujoho: (deviceId: string): string => {
    switch(getLang()) {
      case 'ja':
        return `${deviceId}の端末情報`;
      case 'en':
        return `Device information of ${deviceId}`;
      case 'es':
        return `Información del dispositivo de ${deviceId}`;
      default:
        return ``;
    }
  },
  showNoShinpakusuunojoho: (deviceId: string): string => {
    switch(getLang()) {
      case 'ja':
        return `${deviceId}の心拍数の情報`;
      case 'en':
        return `Heart rate information of ${deviceId}`;
      case 'es':
        return `Información de la frecuencia cardíaca de ${deviceId}`;
      default:
        return ``;
    }
  },
  showDateMD: (date: Date | string): string => {
    const d = typeof date === "string" ? date = new Date(date) : date;

    return `${d.getMonth() + 1}/${d.getDate()}`
  },
  showDate: (date: Date | string): string => {
    const d = typeof date === "string" ? date = new Date(date) : date;

    return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`
  },
  showTime: (time: Date): string => `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}`,
  showShortTime: (time: Date): string => {
    switch(getLang()) {
      case 'ja':
        return toShortDateJA(time);
      case 'en':
        return toShortDateEN(time);
      case 'es':
        return toShortDateES(time);
      default:
        return toShortDateEN(time);
    }
  },
  showの再表示: (title: string): string => {
    switch(getLang()) {
      case 'ja':
        return `${title}の再表示`;
      case 'en':
        return `Re-display ${title}`;
      case 'es':
        return `Volver a mostrar ${title}`;
      default:
        return ``;
    }
  },
  showの位置情報: (name: string): string => {
    switch(getLang()) {
      case 'ja':
        return `${name}の位置情報`;
      case 'en':
        return `${name}'s location information`;
      case 'es':
        return `Información de ubicación de ${name}`;
      default:
        return ``;
    }
  },
  show電話番号は: (n: string): string => {
    switch(getLang()) {
      case 'ja':
        return `電話番号は${n}です`;
      case 'en':
        return `The phone number is ${n}`;
      case 'es':
        return `El número de teléfono es ${n}`;
      default:
        return ``;
    }
  },
  showNoNettyushoRisukudonoJoho: (name: string): string => {
    switch(getLang()) {
      case 'ja':
        return `${name}の熱中症リスク度の情報`;
      case 'en':
        return `${name}'s heat stroke risk information`;
      case 'es':
        return `Información de riesgo de golpe de calor de ${name}`;
      default:
        return ``;
    }
  },
  showNoPerformance: (name: string): string => {
    switch(getLang()) {
      case 'ja':
        return `${name}のパフォーマンスの情報`;
      case 'en':
        return `${name}'s performance information`;
      case 'es':
        return `Información de rendimiento de ${name}`;
      default:
        return ``;
    }
  },
  showName: (name: string): string => {
    if(name === 'マスターアカウント') {
      switch(getLang()) {
        case 'ja':
          return `マスターアカウント`;
        case 'en':
          return `Master Account`;
        case 'es':
          return `Cuenta maestra`;
        default:
          return ``;
      }
    } else {
      switch(getLang()) {
        case 'ja':
          return `${name} さん`;
        case 'en':
          return `${name}`;
        case 'es':
          return `${name}`;
        default:
          return ``;
      }
    }
  }
};

export const showSelectLanguage = async () => {
  const { value: formValues } = await Swal.fire({
    title: Lang.show(ComponentLabel.langLogic, "あなたの言語を選択してください"),
    html: `<div>
      <select id="swal-lang" class="swal2-input" style="width: -webkit-fill-available;">
        <option value="ja">日本語</option>
        <option value="en">English</option>
        <option value="es">Español</option>
      </select>  
    </div>`,
    focusConfirm: false,
    preConfirm: () => {
      const data = {
        lang: document.getElementById('swal-lang')["value"],
      };

      if (!_Languages.includes(data.lang)) {
        return null;
      }

      return data;
    },
  })


  if (formValues) {
    setLanguage(formValues.lang);
  }

  location.reload();
}

export const getLangFromComponent = (component: string) => {
  if (getStorageLaunguage() === "") {
    setLanguage("ja")
    return {
      show: (label: string) => Lang.show(component, label),
      showArray: (labels: string[]) => Lang.showArray(component, labels),
      showNoTanmatsujoho: (deviceId: string) => Lang.showNoTanmatsujoho(deviceId),
      showNoShinpakusuunojoho: (deviceId: string) => Lang.showNoTanmatsujoho(deviceId),
      showDate: Lang.showDate,
      showDateMD: Lang.showDateMD,
      showTime: Lang.showTime,
      showの再表示: (title: string) => Lang.showの再表示(title),
      showNoichijoho: (deviceId: string) => Lang.showの位置情報(deviceId),
      show電話番号は: (n: string) => Lang.show電話番号は(n),
      showNoNettyushoRisukudonoJoho: (name: string) => Lang.showNoNettyushoRisukudonoJoho(name),
      showNoPerformance: (name: string): string => Lang.showNoPerformance(name),
      selectLanguage: showSelectLanguage,
      showName: (name: string): string => Lang.showName(name),
      showShortTime: (time: Date): string => Lang.showShortTime(time)
    }
  }

  return {
    show: (label: string) => Lang.show(component, label),
    showArray: (labels: string[]) => Lang.showArray(component, labels),
    showNoTanmatsujoho: (deviceId: string) => Lang.showNoTanmatsujoho(deviceId),
    showNoShinpakusuunojoho: (deviceId: string) => Lang.showNoShinpakusuunojoho(deviceId),
    showDate: Lang.showDate,
    showDateMD: Lang.showDateMD,
    showTime: Lang.showTime,
    showの再表示: (title: string) => Lang.showの再表示(title),
    showNoichijoho: (deviceId: string) => Lang.showの位置情報(deviceId),
    showの位置情報: (name: string) => Lang.showの位置情報(name),
    show電話番号は: (n: string) => Lang.show電話番号は(n),
    showNoNettyushoRisukudonoJoho: (name: string) => Lang.showNoNettyushoRisukudonoJoho(name),
    showNoPerformance: (name: string): string => Lang.showNoPerformance(name),
    selectLanguage: showSelectLanguage,
    showName: (name: string): string => Lang.showName(name),
    showShortTime: (time: Date): string => Lang.showShortTime(time)
  }
}